import React from "react"

import Container from "../components/Container"
import Heading from "../components/Heading"
import P from "../components/Paragraph"
import PageHeader from "../components/PageHeader"
import Wave from "../components/Wave"
import SeoHelmet from "../components/Seo"

const Page = () => {
  return (
    <>
      <SeoHelmet
        title="Datastory"
        description="A new platform for data storytelling"
        url={`http://lapidus.se/datastory`}
      />
      <PageHeader
        title="Datastory.org – a new platform for open data and storytelling"
        subtitle="Join us on an exciting journey! We are actively seeking researchers and other partners who share our desire to democratize knowledge."
      />
      <Container size="lg" my="1rem">
        <img
          src="https://www.datocms-assets.com/9091/1570259641-datastory.png?w=1400&dpr=2&fit=crop&auto=format"
          alt="Datastory"
          width={1400}
          style={{ width: "100%", height: "auto" }}
        />
      </Container>

      <Container size="sm" my="5rem" className="content">
        <Heading as="h2" fontSize={["lg", "xl"]}>
          {"The inception of Datastory"}
        </Heading>
        <P fontSize={["sm", "lg"]}>
          {
            "Having spent the last fifteen years advancing web development, data visualization and data science, our team decided to build a platform that takes the long view on civic education with data storytelling at its center."
          }
        </P>
        <Heading as="h2" fontSize={["md", "lg"]}>
          {"A shortcut to the audience"}
        </Heading>
        <P fontSize={["sm", "lg"]}>
          {
            "The overarching goal of Datastory is to make statistics, and other information about pressing issues, accessible to citizens, journalists and decision-makers in entirely new ways. With new technology and workflows, we want to help researchers make their critical data and conclusions widely known sooner – and with more richness and depth - than traditional communication would. Leveraging data stories and open data, Datastory can offer a shortcut to an audience."
          }
        </P>

        <Heading as="h2" fontSize={["md", "lg"]}>
          {"Follow the project and apply to be a partner"}
        </Heading>
        <P fontSize={["sm", "lg"]}>
          {
            "Datastory is being developed by a team of expert data scientists, data journalists, project leaders, developers and designers. We are now actively seeking researchers, partners and organizations who want to join us on this journey. Contact us via "}
            <a href="mailto:hello@datastory.org">hello@datastory.org</a>
            {" if you are interested in making your data accessible to the world."
          }
        </P>

         <P fontSize={["sm", "lg"]}>
          {
           " For more information, please visit "
          }
            <a href="https://www.datastory.org" target="_blank" rel="noopener noreferrer">www.datastory.org</a>
        </P>
      </Container>

      <Container mt="5rem">
        <P fontSize={["sm", "lg"]}></P>
      </Container>
      <Wave color="white" variant="bottom3" bg="navy" />
    </>
  )
}

export default Page
