import React from "react"

import Box from "../Box"

const variants = {
  top1:
    "M0,0 C119.952,35.3352113 240.048,70.6704225 360,63.8873239 C479.952,57.1042254 600.048,8.28169014 720,2.36619718 C839.952,-3.54929577 960.048,33.4422535 1080,41.0140845 C1199.952,48.5859155 1320.048,26.6591549 1440,4.73239437 L1440,112 C1320.048,112 1199.952,112 1080,112 C960.048,112 839.952,112 720,112 C600.048,112 479.952,112 360,112 C240.048,112 119.952,112 0,112 L0,0 Z",
  top2:
    "M0,25.4636688 C112.312,35.2559379 224.422,45.0482069 336.734,37.6091188 C448.844,30.1700307 561.156,5.42367636 673.266,12.5591282 C785.578,19.6945801 897.688,58.7118382 1010,55.0682032 C1122.312,51.4245682 1234.422,5.12004011 1346.734,0.413678242 C1458.844,-4.29268363 1571.156,32.4473026 1683.266,45.2000251 C1795.578,57.9527476 1907.688,46.6422973 2020,35.331847 L2020,112 C1907.688,112 1795.578,112 1683.266,112 C1571.156,112 1458.844,112 1346.734,112 C1234.422,112 1122.312,112 1010,112 C897.688,112 785.578,112 673.266,112 C561.156,112 448.844,112 336.734,112 C224.422,112 112.312,112 0,112 L0,25.4636688 Z",
  top3:
    "M0,3.83561644 C80.064,27.3863014 159.984,51.0136986 240.048,46.7945205 C319.968,42.5753425 400.032,10.6630137 479.952,13.0410959 C560.016,15.4191781 639.936,52.2410959 720,48.3287671 C800.064,44.4164384 879.984,-0.0767123288 960.048,-9.90805802e-05 C1039.968,0.0767123288 1120.032,44.7232877 1199.952,53.6986301 C1280.016,62.6739726 1359.936,35.9013699 1440,9.20547945 L1440,112 C1359.936,112 1280.016,112 1199.952,112 C1120.032,112 1039.968,112 960.048,112 C879.984,112 800.064,112 720,112 C639.936,112 560.016,112 479.952,112 C400.032,112 319.968,112 240.048,112 C159.984,112 80.064,112 0,112 L0,3.83561644 Z",
  bottom1:
    "M0,45.8888889 C119.952,71.2444444 240.048,96.6777778 360,94.8888889 C479.952,93.1777778 600.048,64.2444444 720,67.6666667 C839.952,71.0888889 960.048,106.788889 1080,111.222222 C1199.952,115.733333 1320.048,88.9777778 1440,62.2222222 L1440,0 C1320.048,0 1199.952,0 1080,0 C960.048,0 839.952,0 720,0 C600.048,0 479.952,0 360,0 C240.048,0 119.952,0 0,0 L0,45.8888889 Z",
  bottom2:
    "M0,69.8947368 C80.064,80.8421053 159.984,91.7052632 240.048,88.4210526 C319.968,85.1368421 400.032,67.6210526 479.952,73.2631579 C560.016,78.9052632 639.936,107.621053 720,101.052632 C800.064,94.4842105 879.984,52.6315789 960.048,54.7368421 C1039.968,56.8421053 1120.032,102.821053 1199.952,110.315789 C1280.016,117.810526 1359.936,86.6526316 1440,55.5789474 L1440,0 C1359.936,0 1280.016,0 1199.952,0 C1120.032,0 1039.968,0 960.048,0 C879.984,0 800.064,0 720,0 C639.936,0 560.016,0 479.952,0 C400.032,0 319.968,0 240.048,0 C159.984,0 80.064,0 0,0 L0,69.8947368 Z",
  bottom3:
    "M0,70 C240.048,90.3 479.952,110.7 720,111 C960.048,111.3 1199.952,91.7 1440,72 L1440,0 C1199.952,0 960.048,0 720,0 C479.952,0 240.048,0 0,0 L0,70 Z",
}

const Wave = ({ variant = "top", ...restProps }) => {
  return (
    <Box {...restProps}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1440 112"
        preserveAspectRatio="none"
        style={
          variant === "top1" || variant === "top2" || variant === "top3"
            ? { position: "relative", top: "0.3rem" }
            : { position: "relative", bottom: "0.3rem" }
        }
      >
        <path fill="currentcolor" d={variants[variant]} />
      </svg>
    </Box>
  )
}

Wave.defaultProps = {
  height: "7rem",
  color: "navy",
}

export default Wave
